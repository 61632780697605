<template>
  <div>
    <v-row no-gutters>
      <v-progress-linear indeterminate :color="`${systemDetails.themecolor}`" v-if="eventCallListLoading"></v-progress-linear>
      <v-col cols="12" class="pa-2">
        <v-card class="mx-auto">
          <v-toolbar color="grey lighten-5" dense>
            <v-row class="subtitle-1">
              <v-col cols="11">
              <div class="d-flex">
                <v-icon class="pr-2">mdi-calendar</v-icon>
                <h2>{{ $t('events') }} </h2>
                <span class="numberCircle mt-1">{{ listOfEvents.length }}</span>
              </div>
              </v-col>
              <v-col cols="1" class="text-end">
                <v-btn fab dark color="success" x-small @click="openEventDialog" class="ml-n3">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-list v-if="listOfEvents.length">
            <v-list-item-group>
              <template v-for="(item, index) in listOfEvents">
                <v-list-item :key="item._id">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{item.data.name}}
                        <v-chip x-small v-if="item.data && item.data.type && item.data.type.length" class="ma-2" color="info" label text-color="white">
                          {{  item.data.type[0].name }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary">{{ item.data.attendees }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="text--primary">{{ item.data && item.data.start_date ? $formatter.formatDate(item.data.start_date, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : '' }} - {{ item.data && item.data.end_date ? $formatter.formatDate(item.data.end_date, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : '' }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.data.description"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="d-flex">
                      <v-icon color="primary" class="pr-1" @click.stop="editEventHandler(item, index)">mdi-pencil-circle-outline</v-icon>
                      <v-icon color="red"  @click.stop="deleteEventHandler(item, index)">mdi-delete-circle-outline</v-icon>
                      </div>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listOfEvents.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
          <v-alert prominent text v-else type="info" class="mt-2" >
            <v-row align="center">
              <v-col class="grow">{{ $t('noRecords') }}</v-col>
            </v-row>
          </v-alert>
        </v-card>
        <!-- <v-card min-height="200px" class="ma-2" elevation="4">
          <v-card-title>
            <v-icon class="pr-2">mdi-home</v-icon>
            Events
            <div class="numberCircle">30</div>
          </v-card-title>
          <v-divider></v-divider>
        </v-card> -->
      </v-col>
      <v-col cols="12" class="pa-2">
        <v-card class="mx-auto">
          <v-toolbar color="grey lighten-5" dense>
            <v-row>
              <v-col cols="11">
                <div class="d-flex">
                  <v-icon class="pr-2">mdi-phone-log</v-icon>
                  <h2>{{ $t('calls') }} </h2>
                  <span class="numberCircle mt-2">{{ listOfCalls.length }}</span>
                </div>
              </v-col>
              <v-col cols="1" class="text-end">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn fab dark color="success" x-small v-on="on" class="ml-n3">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="openLogDialog('completed')">{{ $t('logCall') }}</v-list-item>
                    <v-list-item @click="openLogDialog('scheduled')">{{ $t('scheduleCall') }}</v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-list two-line v-if="listOfCalls.length">
            <v-list-item-group>
              <template v-for="(item, index) in listOfCalls">
                <v-list-item :key="item.title">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title  class="font-weight-bold"> {{ item.modulerecordname }} ({{ $t(item.modulelabel) }})
                        <v-chip x-small class="ma-2"  :color="item.outgoingstatus === 'completed' ? 'success' : 'info'"  label text-color="white">
                          {{ item.outgoingstatus }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary">
                        {{ item.modified_at ? $formatter.formatDate(item.modified_at, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : '' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="d-flex">
                      <v-icon color="primary" class="pr-1" @click="editCallLogHandler(item)">mdi-pencil-circle-outline</v-icon>
                      <v-icon color="red" @click.stop="deleteCallLogHandler(item)">mdi-delete-circle-outline</v-icon>
                      </div>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listOfCalls.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
          <v-alert prominent text v-else type="info" class="mt-2" >
            <v-row align="center">
              <v-col class="grow">{{ $t('noRecords') }}</v-col>
            </v-row>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="eventDialog" width="1000" persistent>
      <v-card>
        <v-card-title v-if="moduleObj" :style="$vuetify.theme.dark ? '' : `background-color: ${systemDetails.themecolor}; color: ${systemDetails.textcolor}`" class="pa-2">
          <span class="body-1 px-3 font-weight-medium">
            {{ selectedId ? $t('update') : $t('create') }}
          </span>
          <v-spacer></v-spacer>
          <v-btn :color="systemDetails.textcolor" icon @click="closeEventDialog" small>
            <v-icon size="20"> mdi-close-circle </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <module-render :listOfFields="listOfFields" :fields="fields" ref="formReferences" :key="reRender" v-if="eventDialog" :moduleName="moduleObj.name"
            :isRecordCreation="true"></module-render>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveOrUpdateEvent" :loading="eventLoading">{{ selectedId ? $t('update') : $t('save') }}</v-btn>
          <v-btn color="error" @click="closeEventDialog" v-text="$t('close')"></v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog" width="700" persistent>
      <v-card>
        <form-template :references.sync="formReferences" :model="modelObj" ref="fieldsReference" @closeDialog="closeDialog" v-if="showDialog">
          <template slot="custom_fields">
            <v-row no-gutters>
              <v-col cols="4">
                <v-combobox class="custom-autocomplete" v-model="modelObj.moduleid" :items="listOfModules" @change="loadModuleRecord()" :label="$t('module')" outlined dense hide-details item-value="_id" item-text="name" return-object></v-combobox>
              </v-col>
              <v-col cols="8">
                <v-autocomplete class="custom-autocomplete" v-model="modelObj.recordid" :items="listOfSelectedModuleRecords" label="Records" :search-input.sync="searchTerm" dense outlined hide-details prepend-inner-icon="mdi-magnify" item-value="_id" item-text="name"></v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </form-template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mixins from '../../views/CustomModules/mixin'
import FormTemplate from '@/components/FormTemplate'
export default {
  mixins: [mixins],
  data () {
    return {
      eventDialog: false,
      showDialog: false,
      calltypes: [{ name: this.$t('outbound'), _id: 'outbound' }, { name: this.$t('inbound'), _id: 'inbound' }, { name: this.$t('missed'), _id: 'missed' }],
      subMenuOpen: false,
      isScheduledCall: false,
      callStatusItems: [{ name: this.$t('completed'), _id: 'completed' }, { name: this.$t('scheduled'), _id: 'scheduled' }],
      listOfSelectedModuleRecords: [],
      listOfFields: [],
      fields: {},
      reRender: 0,
      moduleName: 'Event',
      selectedId: '',
      moduleObj: {},
      selected: [2],
      modelObj: {},
      MODULE_URL: 'moduledata', // Added to adjust for event save & update
      ticketId: this.$route.params.ticket_id,
      listOfEvents: [],
      listOfCalls: [],
      searchTerm: '',
      eventLoading: false,
      eventCallListLoading: false
    }
  },
  components: {
    'module-render': () => import('../../components/Modules/ModuleFormRender'),
    FormTemplate
  },
  mounted () {
    this.$root.$on('listOfFieldsUpdated', () => {
      this.setFormValues()
    })
    const eventObj = this.listOfModules.find(val => val.name === 'Event')
    this.moduleObj = eventObj
    const query = [{ $match: { Module_Id: this.moduleObj._id } }, { $sort: { Tab_Order: 1 } }]
    this.$api.execute('post', 'modulefields/query', query).then(async (result) => {
      if (result.data) {
        const skip = 0
        this.listOfFields = result.data
        await this.reconstructModuleFieldValues(result.data, true, skip)
      }
    })
    this.getListOfEventsAndCalls()
    this.$eventBus.$on('deleteSuccess', (data) => {
      this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('deleteMsg') })
      if (data === 'events') this.closeDialogHandler()
      else if (data === 'calls') this.closeDialog()
      this.getListOfEventsAndCalls()
      this.$store.commit('hideDialog')
    })
    this.$root.$on('stopLoadingCalendar', () => {
      this.eventLoading = false
    })
  },
  watch: {
    searchTerm (newVal, oldVal) {
      this.loadModuleRecord(newVal)
    }
  },
  computed: {
    ...mapGetters(['listOfModules', 'getUsers', 'systemDetails', 'userDetails', 'formType']),
    formReferences () {
      return {
        title: this.isScheduledCall ? this.$t('scheduleCall') : this.$t('logCall'),
        fromsettings: true,
        properties: [{
          model: 'custom_fields',
          slot: 'custom_fields',
          class: 'sm12 xs12 lg12'
        },
        {
          model: 'type',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.calltypes,
          sselect_value: '_id',
          select_text: 'name',
          label: this.$t('type'),
          class: 'xs12 sm6'
        }, {
          model: 'outgoingstatus',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.callStatusItems,
          select_value: '_id',
          select_text: 'name',
          label: this.$t('outgoingStatus'),
          class: 'xs12 sm6',
          disabled: !this.modelObj._id
        }, {
          model: 'starttime',
          type: this.formType.DATE_TIME_PICKER,
          rules: this.$_requiredValidation,
          picker: 'datePicker',
          menu: 'dateMenu',
          timePicker: 'timePicker',
          label: this.$t('startTime'),
          class: !this.isScheduledCall ? 'sm4 xs12' : 'xs12',
          color: 'primary lighten-1',
          active: 0,
          disabled: false
        }, {
          model: 'duration',
          type: this.formType.TIMEPICKER,
          rules: this.$_requiredValidation,
          label: this.$t('duration'),
          menu: 'timeMenu',
          disabled: false,
          class: !this.isScheduledCall ? 'sm4 xs12' : 'xs12',
          color: 'primary lighten-1'
        }, {
          model: 'owner',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.getUsers,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('owner'),
          class: 'xs12 sm4',
          is_visible: !this.isScheduledCall
        }, {
          model: 'subject',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('subject'),
          class: 'sm12 xs12',
          color: 'primary lighten-1'
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          rules: this.$_requiredValidation,
          label: this.$t('description'),
          class: 'sm12 xs12',
          color: 'primary lighten-1'
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.modelObj._id ? this.$t('update') : this.$t('save'),
          click: this.saveUpdateCalls,
          loading: this.eventLoading,
          is_show: true
        }, {
          name: 'close',
          color: 'error',
          click: this.closeDialog,
          label: this.$t('close'),
          is_show: true
        }]
      }
    }
  },
  methods: {
    setFormValues () {
      if (this.selectedId) {
        this.getSingleRecordHandler(this.selectedId, this.eventObj.type)
      }
    },
    getSingleRecordHandler (id, type) {
      const moduleName = type === 1 ? this.EVENT : this.TASK
      this.eventObj.type = type
      this.getRecordHandler(`moduledata/${moduleName}/get_by_id/${id}`, 'calendar')
    },
    openLogDialog (type) {
      this.isScheduledCall = type === 'scheduled'
      this.modelObj.outgoingstatus = type
      this.modelObj.owner = this.userDetails._id
      this.modelObj.starttime = this.$formatter.formatDate(this.$formatter.getCurrentDate(this.userDetails.dateformat), this.userDetails.dateformat, `${this.userDetails.dateformat} HH:mm`)
      this.modelObj.timePicker = '00:00'
      this.showDialog = true
    },
    closeDialog () {
      this.modelObj = {}
      this.showDialog = false
    },
    openEventDialog () {
      this.eventDialog = true
    },
    toggleSubMenu (menu) {
      if (this.subMenuOpen === menu) {
        this.subMenuOpen = null
      } else {
        this.subMenuOpen = menu
      }
    },
    loadModuleRecord (searchTerm) {
      if (this.modelObj.moduleid && this.modelObj.moduleid._id) {
        // if (this.searchTerm === '' || this.searchTerm === null) {
        //   this.searchTerm = this.modelObj.modulerecordname
        // }
        this.$api.execute('get', `moduledata/${this.modelObj.moduleid.name}/live_search?skip=0&limit=20&searchTerm=${searchTerm || ''}`).then(({ data }) => {
          const listOfRecords = data.data
          this.listOfSelectedModuleRecords = []
          listOfRecords.map(data => {
            this.listOfSelectedModuleRecords.push({
              _id: data._id,
              name: data.data.name
            })
          })
        })
        if (this.searchTerm === '' || this.searchTerm === null) {
          this.modelObj.recordid = ''
        }
      }
    },
    saveUpdateCalls () {
      const payload = this.$formatter.cloneVariable(this.modelObj)
      this.eventLoading = true
      payload.moduleid = payload.moduleid._id
      payload.ticketid = this.ticketId
      payload.starttime = this.$formatter.formatDate(payload.starttime, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
      this.$api.execute(payload._id ? 'put' : 'post', payload._id ? `ticketcalls/${payload._id}` : 'ticketcalls', payload)
        .then(() => {
          this.eventLoading = false
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t(payload._id ? 'updatedSuccess' : 'createdSuccess') })
          this.closeDialog()
          this.getListOfEventsAndCalls()
        })
    },
    getListOfEventsAndCalls () {
      this.eventCallListLoading = true
      this.$api.execute('get', `ticketcalls/get_calls_and_events/${this.ticketId}`).then(({ data }) => {
        this.eventCallListLoading = false
        this.listOfCalls = data.calls
        this.listOfEvents = data.events
        this.eventLoading = false
      })
    },
    saveOrUpdateEvent () {
      this.eventLoading = true
      this.updateRecordHandler({
        id: this.selectedId,
        moduleName: 'Event',
        moduleid: this.moduleObj._id,
        type: '',
        from: 'calendar',
        template_id: '',
        ticketid: this.ticketId
      })
    },
    closeDialogHandler () {
      this.eventDialog = false
      this.fields = {}
      this.selectedId = null
    },
    getEventsAndTasks () { // This is added for mixin file call after event save doot remove until it is handled
      this.getListOfEventsAndCalls()
    },
    editEventHandler (item) {
      this.selectedId = item._id
      this.getRecordHandler(`moduledata/Event/get_by_id/${item._id}`, 'calendar')
      this.eventDialog = true
    },
    deleteEventHandler (item) {
      const payload = { ids: [item._id], url: 'moduledata/Event/delete_permanent_multiple', module: 'events' }
      this.$store.commit('showDeleteDialog', payload)
    },
    editCallLogHandler (item) {
      this.$api.execute('get', `ticketcalls/id?id=${item._id}`).then(({ data }) => {
        this.modelObj = data
        this.modelObj.datePicker = this.$formatter.formatDate(this.modelObj.starttime, 'DD.MM.YYYYTHH.mm.ss', 'YYYY-MM-DDTHH:mm:ss')
        this.modelObj.starttime = this.$formatter.formatDate(this.modelObj.starttime, 'DD.MM.YYYYTHH.mm.ss', 'YYYY-MM-DDTHH:mm:ss')
        this.modelObj.moduleid = {
          _id: this.modelObj.moduleid,
          name: this.$t(this.modelObj.modulelabel)
        }
        this.listOfSelectedModuleRecords = [{
          _id: this.modelObj.recordid,
          name: this.modelObj.modulerecordname
        }]
        this.showDialog = true
      })
    },
    deleteCallLogHandler (item) {
      const payload = { ids: [item._id], url: 'ticketcalls/delete_multiple', module: 'calls' }
      this.$store.commit('showDeleteDialog', payload)
    },
    closeEventDialog () {
      this.eventDialog = false
      this.fields = {}
      this.selectedId = null
    }
  },
  beforeDestroy () {
    this.$root.$off('stopLoadingCalendar')
    this.$eventBus.$off('deleteSuccess')
  }
}
</script>
<style>
.v-messages {
  display: none;
}
.numberCircle {
  border-radius: 25%;
  width: 23px;
  height: 23px;
  padding: 2px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 16px Arial, sans-serif;
  margin-left: 10px;
}
.custom-autocomplete .v-input__control > .v-input__slot {
  border-radius: initial !important;
}
</style>
